
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import Store from '@/store';

import SVG from '@/components/SVG.vue';
import User from '@/models/user.model';
import ProjectService from '@/services/project.service';

export default defineComponent({
	props: ['modelValue', 'left', 'restricted', 'showTitle'],

	emits: ['update'],

	components: {
		SVG,
	},

	setup(props, ctx) {
		const route = useRoute();
		// input element ref

		const input: Ref = ref(null);
		const query = ref('');
		const uid = getCurrentInstance()?.uid || '';

		const modalOpen = ref(false);
		const activeUser = ref();
		const projectUsers: Ref<User[]> = ref([]);

		const index = ref(0);

		// get the users from the store on mounted
		onMounted(async () => {
			projectUsers.value = Store.get('users')?.value;

			if (props.restricted) {
				const projectId = Number(route.params.id);

				await ProjectService.show(projectId).then((response) => {
					if (response.members) {
						const newMembers: User[] = [];

						response.members.forEach((member) => {
							if (member?.member?.role !== 'BOARDMEMBER' && member?.member?.role !== 'STAKEHOLDER') {
								newMembers.push(member);
							}
						});

						projectUsers.value = newMembers;
					}
				});
			}

			load(props.modelValue);
		});

		// get the users from the store on mounted
		watch(props, (propsValue) => {
			load(propsValue.modelValue);
		});

		// Focus the search input when modal is activated
		watch(modalOpen, (value) => {
			if (!value) {
				return;
			}

			nextTick(() => {
				input.value.focus();
			});
		});

		// Loading users
		function load(userId: number) {
			if (!userId) {
				activeUser.value = null;
			}

			if (!projectUsers.value) {
				return;
			}

			projectUsers.value.forEach((projectUser: User) => {
				if (projectUser.id === userId) {
					activeUser.value = projectUser;
					ctx.emit('update', userId);
				}
			});
		}

		// get user's full name
		function getFullName(user: User) {
			return `${user.first_name} ${user.last_name}`;
		}

		// list of users that are included in the search result
		const users = computed(() => {
			if (!projectUsers.value) {
				return [];
			}

			if (!projectUsers.value) {
				return [];
			}

			const lowerCaseQuery = query.value.toLowerCase();

			// filter out the users that do not match the query
			return projectUsers.value.filter((user: User) => getFullName(user).toLowerCase().includes(lowerCaseQuery));
		});

		function closeModal() {
			query.value = '';
			index.value = 0;

			modalOpen.value = false;
		}

		// add a user to the list
		function selectUser(user: User) {
			if (!user) {
				return;
			}

			activeUser.value = user;

			ctx.emit('update', user.id);

			closeModal();
		}

		function removeUser() {
			ctx.emit('update', null);

			activeUser.value = null;
		}

		function selectUserAtCurrentIndex() {
			selectUser(users.value[index.value]);
		}

		function arrowUp() {
			if (index.value <= 0) {
				return;
			}

			index.value--;
		}

		function arrowDown() {
			if (index.value >= users.value?.length - 1) {
				return;
			}

			index.value++;
		}

		function handleModalClickAway(target: Element) {
			if (target.closest(`#add-user-${uid}`)) {
				return;
			}

			closeModal();
		}

		function isExternalUser(userRoles: Array<string>) {
			let returnValue = false;

			if (userRoles) {
				userRoles.forEach((role) => {
					if (role === 'External user') {
						returnValue = true;
					}
				});
			}

			return returnValue;
		}

		return {
			// data
			uid,
			input,
			query,
			index,
			activeUser,
			modalOpen,

			// computed
			users,

			// functions
			getFullName,
			selectUser,
			closeModal,
			removeUser,
			selectUserAtCurrentIndex,
			arrowUp,
			arrowDown,
			handleModalClickAway,
			isExternalUser,
		};
	},
});
